import React from 'react'
import './About.css'

const About = () => {
  return (
    <div className='about-page'>
      <div className='about-content'>
        <div className='about-intro'>
          <h1>Our Company Growth</h1>
          <p>ADSYS Pty Ltd was established in 2005 as a small Australian engineering company. Starting out as a consultancy company specialising in project management and factory layouts, ADSYS has grown considerably and expanded its services, products and goals throughout the years. It has progressed from consultancy to manufacturing equipment to developing material handling systems.</p>
          <p>Today, ADSYS specialises in total electrical control, including developing supporting PLC software, total turnkey systems, robotics and automation, in addition to designing, manufacturing and installation of quality equipment and control systems. ADSYS is proud to have developed many long-term clients whilst continually expanding its client base with its success.</p>
        </div>
        <div className="about-section-content">
          <h1>A Brief Overview</h1>
          <OverviewTimeline/>
        </div>
      </div>
    </div>
  )
}

const OverviewTimeline = () => {
  const Timeline = 
  [{year: "2022", 
    job: [
      {title: "VISY-CCP", text: "Designed and manufactured pallet sweeps and infeed conveyors"},
      {title: "AustCor - ABBE", text: "Designed and manufactured conveyor take-off system for BHS-Universal fanfold corrugated. Managed, integrated, installed, and commissioning all new handling systems into exiting corrugator take-off system."},
      {title: "OJI Lansvale", text: "Designed and manufactured bottom sheet inserter for Ward take-off conveyors. Integrated, installation supervision, and commissioning."},
      {title: "VISY Warwick Farm", text: "Designed and manufactured finished goods conveyors system. PLC programmed, integrated, installed, and commissioning all new conveyors system into existing finished goods conveyors system."},
    ]},
    {year: "2021",
    job: [
      {title: "OJI Yatala", text: "Designed and manufactured top sheets systems. PLC programmed, integrated, installed, and commissioning into exiting Emmepi conveyors system and Signode strapping machine"},
      {title: "ABBE Corrugated", text: "Designed, manufactured, and programmed bundle take off conveyor"},
      {title: "OJI Shepparton", text: "Researched and developed special purpose bundle breaker"},
      {title: "OJI Yatala", text: "Designed and manufactured turntable for finished system"},
    ]},
    {year: "2020",
    job: [
      {title: "OJI Noble Park", text: "Designed and manufactured 90-degree curve bundle conveyors"},
      {title: "OJI Lansvale", text: "Designed and manufactured top sheets systems."},
      {title: "OJI Lansvale", text: "Designed layout bundle conveyors take-off system for Mitsubishi Evol. Managed, integrated, installed, and commissioning all new handling systems from Mitsubishi Evol via Signode strapper into Fuji robot palletizer."},
      {title: "OJI Levin, New Zealand", text: "Designed and manufactured Bobst infeed and bundle conveyors."},
      {title: "OJI Lansvale", text: "Designed and manufactured finished good conveyors."},
    ]},
    {year: "2019",
    job: [
      {title: "OJI Noble Park", text: "Designed and manufactured bottom sheet inserter for Ward take-off conveyors. Integrated, installation supervision, and commissioning"},
      {title: "OJI Noble Park", text: "Designed and manufactured Ward take-off conveyors and rotating conveyors. Integrated, installation supervision, and commissioning"},
      {title: "Orora Scoresby", text: "Lifting bundle plastic belt conveyors."},
      {title: "VISY Truganina", text: "Designed and Manufactured BGM take-off bundle conveyors. Integrated, installation supervision, and commissioning."},
      {title: "Orora Scoresby", text: "Designed and manufactured bundle plastic belt conveyors."},
      {title: "OJI Levin, New Zealand", text: "Designed and manufactured Tanabe bundle conveyors."},
    ]},
    {year: "2018",
    job: [
      {title: "VISY Smithfield", text: "Designed and manufactured bundle conveyors.PLC programmed, integrated, installed, and commissioning."},
      {title: "VISY Truganina", text: "Designed and manufactured bundle conveyors.PLC programmed, integrated, installed, and commissioning."},
      {title: "VISY Dandenong", text: "Designed and manufactured turntable conveyer."},
      {title: "OJI Auckland, New Zealand", text: "Designed and manufactured bottom sheet inserter for Ward take-off conveyors. Integrated, installation supervision, and commissioning."},
      {title: "OJI Noble Park", text: "Designed and manufactured conveyors system for finished goods line. Integrated, installation supervision, and commissioning"},
      {title: "OJI Noble Park", text: "Designed and manufactured Emba infeed conveyors."},
      {title: "Orora Revesby", text: "Designed and manufactured conveyors system for Bobst 820 infeed conveyor. Integrated, installation supervision, and commissioning"},
    ]},
    {year: "2017",
    job: [
      {title: "Orora Revesby", text: "Designed layout for Bobst 1228 foundation."},
      {title: "Orora Specialty, Rocklea", text: "Designed factory layouts and product flow configuration. Designed foundation layouts and drawings."},
      {title: "Orora Specialty, Smithfield", text: "Provided site survey and layout drawings, relocated equipment to Rocklea"},
    ]},
    {year: "2016",
    job: [
      {title: "Orora, Brooklyn", text: "Designed layout for Bobst 1628 foundation.Managed, integrated, installed, and commissioning all new handling systems into exiting finished goods line.Supervised installation and supplied new scrap conveyor."},
      {title: "OJI Noble Park", text: "Managed, installed, and commissioning automated corrugator conveyors take-off system."},
    ]},
    {year: "2015",
    job: [
      {title: "Orora, Hasting NZ", text: "Layout and supervised Martin 1628 relocation. Supplied new conveyors handling system."},
      {title: "Orora, Auckland NZ", text: "Designed and supplied special X-Y conveyors turntable for finished goods line."},
    ]},
    {year: "2014",
    job: [
      {title: "Orora Launceston", text: "Designed and supplied robot palletiser system. Managed, installed and commissioning robot palletiser system"},
      {title: "Charta, Auckland NZ", text: "Designed and supplied special pallet sweep system."},
      {title: "CCH, Lansvale", text: "Automated and integrated new material handling systems into existing transfer car."},
      {title: "Orora, Brooklyn", text: "Managed, installed and commissioning corrugator take-off conveyors stages 2 and 3, layout designed conveyors systems for Bobst die cutter relocation."},
    ]},
    {year: "2013",
    job: [
      {title: "Amcor, Launceston", text: "Provided feasibility costing to reduce manual handling and safely isolating forklifts from operators. Designed layout and procured necessary handling equipment. Managed project, installed and commissioning new and used handling equipment. Designed and manufactured special pallet dispensers and conveyors, automated material handling systems for new equipment."},
      {title: "Amcor, Rocklea", text: "Designed and manufactured pallet inserter and bypass lines conveyors, designed foundation layouts and drawings for new equipment,automated material handling systems for new equipment"},
      {title: "Amcor, Brooklyn", text: "Designed and manufactured corrugator take-off conveyor."},
      {title: "Amcor, Brooklyn", text: "Designed and manufactured infeed conveyors."},
    ]},
    {year: "2012",
    job: [
      {title: "Amcor, Rocklea", text: "Designed factory layouts and product flow configuration, Designed foundation layouts and drawings, automated material handling systems."},
      {title: "Amcor, Brooklyn", text: "Designed and manufactured infeed conveyors."},
      {title: "Boral", text: "Designed and manufactured movable backstops for stacking line."},
    ]},
    {year: "2011",
    job: [
      {title: "Amcor, Rocklea", text: "Recovered machinery equipment due to Brisbane floods."},
      {title: "Amcor, Brooklyn", text: "Designed and manufactured infeed conveyors."},
    ]},
    {year: "2010",
    job: [
      {title: "Amcor, Regency Park", text: "Designed factory layouts and product flow configuration, designed foundation layouts and drawings,automated material handling systems."},
      {title: "Guala Closures, Sunshine", text: "Automated material handling systems, supplied special-built purpose conveyors."},
      {title: "Amcor Closures, SA ", text: "General consultancy engineering."},
    ]},
    {year: "2009",
    job: [
      {title: "Impact", text: "Designed factory layouts and product flow configuration."},
      {title: "Amcor, Athol Park", text: "Provided engineering project management, provided factory layout options for new equipment and warehouse, designed, manufactured and installed conveyor systems for new converting machine, provided turnkey system for transferring conveyors to new warehouses."},
      {title: "Mesh & Bar", text: "Designed, manufactured, and installed conveyors for reinforced bar."},
      {title: "Citypak, Somerton", text: "Designed and manufactured automated strapping and stretch wrap conveyor systems."},
    ]},
    {year: "2008",
    job: [
      {title: "Sita Environment Solutions", text: "General engineering consultancy."},
      {title: "Amcor, Bibra Lake", text: "Designed and provided manufacturer drawings for structure supports. Designed machinery and equipment upgrades."},
      {title: "ACB, Wetherill Park", text: "Removed corrugated equipment and reinstated environment to original condition."},
      {title: "Golden Cockerel", text: "Designed and provided general factory layout drawings."},
    ]},
    {year: "2007",
    job: [
      {title: "Amcor, Smithfield", text: "Provided a full turnkey conveyor system for new installations."},
      {title: "Amcor, Revesby", text: "Designed, manufactured, and integrated robotic load forming systems for converting machines."},
      {title: "Amcor, Townsville", text: "Designed layout of corrugated equipment, upgraded conveyor system for finished goods."},
    ]},
    {year: "2006",
    job: [
      {title: "Amcor, Rocklea", text: "$40 million project and engineering management for relocation of corrugated equipment."},
      {title: "Amcor, Brooklyn", text: "Finished goods layout, designed and manufactured conveyor systems."},
      {title: "Amcor, Scoresby", text: "Designed and manufactured material handling equipment for converting machine."},
      {title: "Citypak, Somerton", text: "Designed factory layouts and product flow configuration, relocation of corrugated equipment."},
    ]},
    {year: "2005",
    job: [
      {title: "Brisbane Steel Fabrication", text: "Upgraded engineering designs to comply with relevant Australian standards."},
      {title: "VISY Automation", text: "Designed and manufactured pallet dispensers."},
    ]},
  ]
  return (
    <div className='timeline-container'>
      {Timeline.map((item, index) => {
        return (
          <div key={index} className="timeline-row">
            <h4 className='timeline-year'>{item.year}<div className='timeline-year-line'/></h4>
            <div>
              {item.job.map((subItem, index) => (
                <div key={index} className="timeline-row-content">
                  <i className='timeline-title'>
                    {subItem.title}
                  </i>
                  <p className='timeline-text'>
                    {subItem.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default About