import React from 'react'
import './Control.css'

const Control = () => {
  return (
    <div className='control-page'>
      page
    </div>
  )
}

export default Control