import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Header.css'

import logoAdsys from '../assets/logoAdsys.png'

const Header = () => (
  <>
    <Banner/>
    <Navbar/>
  </>
)

// Banner and logo section

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <img
          className="banner-logo"
          src={logoAdsys}
          alt="Adsys Logo"/>
      </div>
    </div>    
  )
}

const NavButtons = () => {
  return (
    <>
      <Link to='/' className='home-navbar'> HOME 
        <bar className="home-nav-highlight"/>
      </Link>
      <Link to='/products' className='products-navbar'>PRODUCTS
        <bar className="products-nav-highlight"/>
      </Link>
      <Link to='/services' className='services-navbar'>SERVICES
        <bar className="services-nav-highlight"/>
        {/* <Dropdown menuItems={servicesMenu} menuType={'services-ddm'}/> */}
      </Link>
      <Link to='/about' className='about-navbar'>ABOUT
        <bar className="about-nav-highlight"/>
      </Link>
      <Link to='/gallery' className='gallery-navbar'>GALLERY
        <bar className="gallery-nav-highlight"/>
      </Link>
      <Link to='/contact' className='contact-navbar'>CONTACT
        <bar className="contact-nav-highlight"/>
      </Link>
    </>
  
  )
}


const Navbar = () => {
  const [dropDownActive, setDropDownActive] = useState(false)
  const clickDropdown = () => {
    setDropDownActive(!dropDownActive)
  }
  return (
    <>
      <div className="navbar-container">
        <div className="navbar-btns-container">
          <div className={dropDownActive ? "hamburger-active" : "hamburger"} onClick={() => clickDropdown()}>
            <div className={dropDownActive ? "hamburger-1-active" : "hamburger-1"}></div>
            <div className={dropDownActive ? "hamburger-2-active" : "hamburger-2"}></div>
            <div className={dropDownActive ? "hamburger-3-active" : "hamburger-3"}></div>
            <div className={dropDownActive ? "hamburger-4-active" : "hamburger-4"}></div>
          </div>
          <ul className="navbar-default">
            <NavButtons/>
          </ul>
          <ul className={dropDownActive ? "navbar-dropdown-active" : "navbar-dropdown"}>
            <NavButtons/>
          </ul>
          {/* <Link to='/WIP-gotoexternallink' className="adsysapp-login">LOGIN</Link> */}
        </div>
      </div>
    </>
  )
}

export default Header