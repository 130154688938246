import React, {useState} from 'react'
import './Gallery.css'

// import testImg from '../../assets/testimg.jpg'

import bundle1 from "../../assets/photos/BundleAndHandling/20161124_120309.jpg"
import bundle2 from '../../assets/photos/BundleAndHandling/20200414_082820.jpg'
import bundle3 from '../../assets/photos/BundleAndHandling/20201215_114456.jpg'
import bundle4 from '../../assets/photos/BundleAndHandling/20210616_080005.jpg'
import bundle5 from '../../assets/photos/BundleAndHandling/20210618_143615.jpg'
import bundle6 from '../../assets/photos/BundleAndHandling/20211105_151802.jpg'

import scrap1 from "../../assets/photos/ConvertingScrap/20161125_155255.jpg"
import scrap2 from "../../assets/photos/ConvertingScrap/20161126_140007.jpg"
import scrap3 from "../../assets/photos/ConvertingScrap/20161126_140211.jpg"
import scrap4 from "../../assets/photos/ConvertingScrap/20200909_112920.jpg"

import takeoff1 from "../../assets/photos/TakeOff/20160731_164508.jpg"
import takeoff2 from "../../assets/photos/TakeOff/20161125_095734.jpg"
import takeoff3 from "../../assets/photos/TakeOff/20161125_100033.jpg"

import infeed1 from "../../assets/photos/Infeed/20180105_151956.jpg"
import infeed2 from "../../assets/photos/Infeed/20180426_124345.jpg"
import infeed3 from "../../assets/photos/Infeed/20180426_124605.jpg"
import infeed4 from "../../assets/photos/Infeed/20221214_125855.jpg"
import infeed5 from "../../assets/photos/Infeed/20221214_125914.jpg"

import finished1 from "../../assets/photos/FinishedGoods/20161124_120236.jpg"
import finished2 from "../../assets/photos/FinishedGoods/20180807_111827.jpg"
import finished3 from "../../assets/photos/FinishedGoods/20200606_184756.jpg"
import finished4 from "../../assets/photos/FinishedGoods/20201019_092024.jpg"
import finished5 from "../../assets/photos/FinishedGoods/20220126_093007.jpg"
import finished6 from "../../assets/photos/FinishedGoods/20220506_104928.jpg"

import loadformer1 from "../../assets/photos/PalletiserLoadFormer/20160414_145830.jpg"
import loadformer2 from "../../assets/photos/PalletiserLoadFormer/20160414_145853.jpg"
import loadformer3 from "../../assets/photos/PalletiserLoadFormer/20161111_083727.jpg"

import topsheet1 from "../../assets/photos/TopSheet/20210805_094433.jpg"
import topsheet2 from "../../assets/photos/TopSheet/20210911_184325.jpg"
import topsheet3 from "../../assets/photos/TopSheet/20211123_132956.jpg"

import Belt from '../../assets/photos/other/Belt.jpg'
import Chain from '../../assets/photos/other/Chain.jpg'
import Infeed from '../../assets/photos/other/Infeed.jpg'
import Outfeed from '../../assets/photos/other/Outfeed.jpg'
import Roller from '../../assets/photos/other/Roller.jpg'
import Turning from '../../assets/photos/other/Turning.jpg'
import Turntable from '../../assets/photos/other/Turntable.jpg'
import XY from '../../assets/photos/other/XY.jpg'


const Gallery = () => {

  let data = [
    {heading: 'Bundle Conveyers & Handling Systems',
    images: [
      {imgSrc: bundle1},
      {imgSrc: bundle2},
      {imgSrc: bundle3},
      {imgSrc: bundle4},
      {imgSrc: bundle5},
      {imgSrc: bundle6},
    ]},
    {heading: 'Converting Scrap Conveyers Systems',
    images: [
      {imgSrc: scrap1},
      {imgSrc: scrap2},
      {imgSrc: scrap3},
      {imgSrc: scrap4},
    ]},
    {heading: 'Corrugator Take off Conveyers',
    images: [
      {imgSrc: takeoff1},
      {imgSrc: takeoff2},
      {imgSrc: takeoff3},
    ]},
    {heading: 'Infeed to Converting Machines',
    images: [
      {imgSrc: infeed1},
      {imgSrc: infeed2},
      {imgSrc: infeed3},
      {imgSrc: infeed4},
      {imgSrc: infeed5},
    ]},
    {heading: 'Finished Goods Line Conveyer Systems',
    images: [
      {imgSrc: finished1},
      {imgSrc: finished2},
      {imgSrc: finished3},
      {imgSrc: finished4},
      {imgSrc: finished5},
      {imgSrc: finished6},
    ]},
    {heading: 'Palletiser and Load Former',
    images: [
      {imgSrc: loadformer1},
      {imgSrc: loadformer2},
      {imgSrc: loadformer3},
    ]},
    {heading: 'Top Sheet Inserter',
    images: [
      {imgSrc: topsheet1},
      {imgSrc: topsheet2},
      {imgSrc: topsheet3},
    ]},
    {heading: 'Other',
    images: [
      {imgSrc: Belt},
      {imgSrc: Roller},
      {imgSrc: Chain},
      {imgSrc: XY},
      {imgSrc: Turntable},
      {imgSrc: Turning},
      {imgSrc: Infeed},
      {imgSrc: Outfeed},
    ]},
  ]
  const [modal, setModal] = useState(false)
  const [tempImgSrc, setTempImgSrc] = useState('')
  const getImg = (imgSrc) => { 
    setTempImgSrc(imgSrc);
    setModal(true);
  }
  return (
    <div className='gallery-page'>
      <div className={modal ? "gallery-modal-open" : "gallery-modal-closed"}
        onClick={()=>setModal(false)}>
        <img className='gallery-modal-image' src={tempImgSrc} alt=''/>
      </div>
      <div className="gallery-content">
          {data.map((item, index)=>{
              return (
                <div key={index}>
                  <h1>{item.heading}</h1>
                  <div className='gallery-image-grid'>
                    {item.images.map((item, index)=>{
                      return (
                        <img className='gallery-thumbnails' alt='' src={item.imgSrc} key={index} onClick={()=>getImg(item.imgSrc)}/>                
                      )
                    })}
                  </div>
                </div>
              )
          })}
      </div>
    </div>
  )
}



export default Gallery