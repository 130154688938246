import React from 'react'
import './CardSlider.css'

import Slider from 'react-slick';
import "../../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../../node_modules/slick-carousel/slick/slick-theme.css"; 

const CardSlider = ({cards}) => {
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    speed: 1000,
    autoplaySpeed: 2000,
    autoplay: true,
    centerMode: true,
    responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 550,
                settings: {
                  slidesToShow: 1,
                }
              }
            ]
  };

  return (
    <div className='cardslider-relative'>
    <Slider {...settings} className='cardslider-content'>
      {cards.map((card, index) => (
        <div key={index}>
          <div className="cardslider-card" 
          style={{
            backgroundImage: `url(${cards[index].image})`,
          }}>
            <h2>{card.title}</h2>
          </div>
        </div>
      ))}
    </Slider>
    <div className="csc-fade-left"/>
    <div className="csc-fade-right"/>
    </div>
  )
}

export default CardSlider