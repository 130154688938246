import React from 'react'
import './Products.css'
import testImg from '../../assets/testimg.jpg'

// images Component Imports
import Bundle from '../../assets/products/bundle.png'
import FinishedGood from '../../assets/products/finishedGood.png'
import HMI from '../../assets/products/HMI.png'
import Infeed from '../../assets/products/infeed.png'
import PalletInserter2 from '../../assets/products/palletInserter2.png'
import Palletiser from '../../assets/products/palletiser.png'
import Scrap from '../../assets/products/scrap.png'


// import SectionBreak from '../../components/SectionBreak'
import './SectionContent.css'

const Products = () => {
  return (
    <div className='products-page'>
      <div className="products-content">
        <div className='products-intro'>
          <h1>Our Products</h1>
          <p>As an innovative, flexible, and competitive company, we locally design, manufacture, and program all our products specific to the customer's needs, whether it be a turnkey system or a conveyor system. The company can manage the full process from the design through to installation and maintenance or can simply supply a supplementary system to an existing machine. We offer efficient, high-quality, cost-effective material handling products which are customised to the client's requirements whilst complying to all Australian health and safety standards.</p>
        </div> 
        <SectionContentA content={{
          heading: "Corrugators Take Off Conveyers",
          text: 
            <ul>
              <li>Full intergration with corrugators</li>
              <li>Complete load separation & forming system</li>
              <li>Palletiser</li>
            </ul>,
          image: testImg,
          }}/>
        <SectionContentA content={{
          heading: "Infeed Conveyers to Converting Machines",
          text: 
            <ul>
              <li>Forklift entry conveyers</li>
              <li>Load accumulation conveyers</li>
              <li>Load separation conveyers</li>
            </ul>,
          image: Infeed,
          }}/>
        <SectionContentA content={{
          heading: "Converting Scrap Conveyers System",
          text: 
            <ul>
              <li>Low Profile Scrap Conveyer</li>
              <li>High speed multiple converting machines scrap conveyer</li>
            </ul>,
          image: Scrap,
          }}/>
        <SectionContentA content={{
          heading: "Bundle Conveyers & Handling Systems",
          text: 
            <ul>
              <li>Plastic & rollers bundle conveyer</li>
              <li>Radius conveyers</li>
              <li>90 degree transfer conveyer</li>
              <li>Bundle separation and accumulation conveyer</li>
              <li>X-Y conveyers</li>
              <li>Centraliser</li>
              <li>Rotator</li>
            </ul>,
          image: Bundle,
          }}/>
        <SectionContentA content={{
          heading: "Palletiser / Load Former",
          text: 
            <ul>
              <li>Pallet load up to 20 popular stacking patterns</li>
              <li>Intergration with bottom sheets & tie sheets</li>
              <li>Intergration with existing equipment</li>
              <li>Intergrated database control system</li>
              <li>HMI with maintenance & machine performance monitoring systems</li>
            </ul>,
          image: Palletiser,
          }}/>
        <SectionContentA content={{
          heading: "Central Pallet Inserters & Pallet Dispenser",
          text: 
            <ul>
              <li>Multiple or single type pallet insert</li>
              <li>Product tracking system for correct pallet type</li>
            </ul>,
          image: PalletInserter2,
          }}/>
        <SectionContentA content={{
          heading: "Finished Goods Line Conveyer Systems",
          text: 
            <ul>
              <li>Plastic modular belt conveyers</li>
              <li>Roller coveyers</li>
              <li>Chain conveyers</li>
              <li>X-Y conveyers</li>
              <li>Chain transfers</li>
              <li>Transfer car</li>
              <li>Turntables</li>
              <li>HMI with product tracking system</li>
            </ul>,
          image: FinishedGood,
          }}/>
        <SectionContentA content={{
          heading: "HMI Equipment Moonitoring Information System",
          text: 
            <ul>
              <li>Machine utilisation</li>
              <li>Machine downtime</li>
              <li>Machine monitoring system</li>
              <li>Machine speed optimistation</li>
              <li>Maintenance monitoring system</li>
              <li>Energy monitoring system</li>
              <li>Custom design integration and report system</li>
            </ul>,
          image: HMI,
          }}/>
      </div>
    </div>
  )
}

const SectionContentA = ({content}) => {
  return (
    <div className='sca-grid'>
      <div className='sca-image-container'>
        <div className="sca-image" 
          style={{backgroundImage: `url(${content.image})`}}/>
      </div>
      <div className="sca-content">
        <h2>{content.heading}</h2>
        <p>{content.text}</p>
      </div>
    </div>
  )
}

export default Products