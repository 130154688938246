import React from 'react'
import './Systems.css'

// import {productsMenu, conveyerMenu} from '../../layouts/Header.js'

const Systems = () => {
  return (
    <div className='systems-page'>
      <div className='systems-navigation-container'>
        <div className="systems-navigation-content">
          <h1>SYSTEMS NAVIGATION</h1>
          {/* <div className="systems-navigation-grid">
            {productsMenu.map((item, index) => (
            <div key={index} className='systems-navigation-grid-card'>
              <h2>
                {item.heading}
              </h2>
              <ul>
                {item.subHeading.map((item, index) => (
                  <li key={index}>
                    {item.item}
                  </li>
                ))}
              </ul>
            </div>
            ))}
          </div> */}
        </div>
        <div className="systems-navigation-content">
          <h1>SYSTEMS NAVIGATION</h1>
          {/* <div className="systems-navigation-grid">
            {conveyerMenu.map((item, index) => (
            <div key={index} className='systems-navigation-grid-card'>
              <h2>
                {item.heading}
              </h2>
              <ul>
                {item.subHeading.map((item, index) => (
                  <li key={index}>
                    {item.item}
                  </li>
                ))}
              </ul>
            </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Systems