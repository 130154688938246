import React from 'react'
import './SectionBreak.css'

const SectionBreak = () => {
  return (
    <div className='section-break-container'>
      <div className="section-break-content">
        <div className="section-break-element">
        </div>
      </div>
    </div>
  )
}

export default SectionBreak