import React, { useState, useEffect } from 'react'
import './HeroSlider.css'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { CgLoadbar } from 'react-icons/cg'

const HeroSlider = ({slides}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  // const slideStyles = {
  //   backgroundImage: `url(${slides[currentIndex].image})`,
  // }
  const clickLeft = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex)
  }
  const clickRight = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex)
  }
  const goToSlide = slideIndex => {
    setCurrentIndex(slideIndex)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const newIndex = (currentIndex + 1) % `${slides.length}`;
      setCurrentIndex(newIndex)
    }, 5000);
    return () => clearInterval(timer);
  }, [slides, currentIndex]);

  return (
    <div className='sliderStyles'>
      <div className='leftArrowStyles' onClick={clickLeft}><BiChevronLeft/></div>
      <div className='rightArrowStyles' onClick={clickRight}><BiChevronRight/></div>
      {/* <div className='slideStyles' style={slideStyles}></div> */}
      {slides.map((slide, slideIndex) => (
        <div key={slideIndex} 
          style={{
            backgroundImage: `url(${slides[slideIndex].image})`,
          }}
          className={currentIndex === slideIndex ? "slideStyles" : "slideStylesOff"}>
          <div className= {currentIndex === slideIndex ? "slideTextContainer" : "slideTextContainerOff"}>
            <div className='hero-title'><h1>{slides[currentIndex].title}</h1></div>
            <div className='hero-subtitle'><b>{slides[currentIndex].subtitle}</b></div>
          </div>
        </div>
      ))}
      <div className='dotsContainerStyles'>
        {slides.map((slide, slideIndex) => (
          <div key={slideIndex} 
          className={currentIndex === slideIndex ? "dotStylesActive" : "dotStyles"} 
          onClick={() => goToSlide(slideIndex)}>
            <CgLoadbar/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HeroSlider