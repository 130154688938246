import React from 'react'
import './Contact.css'

import mapImage from '../../assets/contact/contact-us-map2.png'


const Contact = () => {
  return (
    <div className='contact-page' style={{backgroundImage: `url(${mapImage})`}}>
      <div className="contact-container">
        <div className='contact-us-container'>
          <h1 className="">Contact us now</h1>
          <sub className="contact-subheading">We will assist you with all sales, project and support enquiries</sub>
          <div className="">
            <ContactDetails/>
          </div>
        </div>
        <div className='contact-form-container'>
          <h1 className="">Online Contact Form</h1>
          <sub className="contact-subheading">You can also use our online contact form to send us a message, and we will get back to you as soon as possible.</sub>
          <form className='contact-form-content'>
            <div>Name: *</div>
            <input type="text" />
            <div>E-mail: *</div>
            <input type="text" />
            <div>Message: *</div>
            <div>
              <input type="text" className='contact-form-input-message'/>
              <input type="submit" className='contact-form-input-submit'/>
            </div>
          </form>
          <sub>NB: * denotes required field</sub>
        </div>
      </div>
    </div>
  )
}

const ContactDetails = () => {
  const contactDetails = [
    {heading: 'Sales' , telephone: '+61 423 809 809', fax: '+61 731 612 916', email: 'sales@adsys.com.au'},
    {heading: 'Administration' , telephone: '+61 412 788 362', fax: null, email: 'admin@adsys.com.au'},
    {heading: 'Technical' , telephone: '+61 421 514 627', fax: null, email: 'support@adsys.com.au'},
  ]
  return (
    <div className="contact-us-content">
    {contactDetails.map((item, index) => {
      return (
        <div className="contact-us-detail-row" key={index}>
          <h4 className="contact-us-detail-heading">
            {item.heading}
          </h4>
          <div className="contact-us-detail-details">
            <div style={ item.telephone ? { display:'block'} : {display : 'none'} }> 
              Telephone: </div>
            <div style={ item.telephone ? { display:'block'} : {display : 'none'} }> 
              {item.telephone} </div>
            <div style={ item.fax ? { display:'block'} : {display : 'none'} }> 
              Fax: </div>
            <div style={ item.fax ? { display:'block'} : {display : 'none'} }> 
              {item.fax} </div>
            <div style={ item.email ? { display:'block'} : {display : 'none'} }> 
              E-mail: </div>
            <div style={ item.email ? { display:'block'} : {display : 'none'} }> 
              {item.email} </div>
          </div>
        </div>
      )
    })}
    </div>
  )
}

export default Contact