import React from 'react'
import './Services.css'

const Services = () => {
  return (
    <div className='services-page'>
      <div className='services-content'>
        <div className='services-intro'>
          <h1>Our Services</h1>
          <p>The solutions that ADSYS delivers are specially customised to the client's requests, and therefore are not limited to the summary below. We provide a range of services and are constantly expanding our skill base, allowing further diversity in future projects we undertake. All services provided are professional, creative, cost-effective, and customisable, while complying with all current health and safety standards.</p>
        </div>
        <div className="services-section-content">
          <h2>Project Management (Project Planning & Scheduling)</h2>
          <p>Upon contracting a service, ADSYS displays professional and organised project management skills. The project scope is established alongside clients to control the budget and requirements, followed by timing with project planning and scheduling. The necessary human resources and equipment required to complete the project are sourced, and then the project is subjected to tracking and monitoring over its course.</p>
          <h2>Proposal Layouts & Options</h2>
          <p>ADSYS thoroughly studies the client's requirements and provides the most viable and cost-effective options, which are then conferred with the client for feedback or changes. In order for the client to make an informed decision, ADSYS outlines the advantages and disadvantages of each option, allowing the client to determine the optimal choice. Once the client is satisfied, the layout is finalised, and CAD layout drawings are provided.</p>
          <h2>Feasibility Studies</h2>
          <p>Upon presenting a feasibility study, ADSYS primarily examines the benefits for the client. A general estimation cost for the proposed designs is delivered, followed by the payback benefits, and return of investment. The project costs are further systematically divided to the client's preferences to achieve an informed decision.</p>
          <h2>System Design (Operational, Layout, Automated)</h2>
          <p>A system design is customised to the client's specifications. It often involves the integration of existing equipment with new equipment to comply with time and budget requirements. ADSYS provides excellent control automation and intuitive human interface controls. In addition, it is possible to integrate methods of product identification and relevant information between systems for maximum efficiency while greatly reducing risk of error.</p>
          <h2>Custom Design (Structure, Mechanical & Electrical Control)</h2>
          <p>ADSYS also designs, manufactures, and installs customised machines for client specified purposes. The specialised equipment requirements are thoroughly researched and developed within the client scope, and the electrical design and control systems software are then tailored to their requirements.</p>
          <h2>CAD Drafting (Site layout, Foundation, Mechanical & Manufacture)</h2>
          <p>CAD drafting is available with all ADSYS services, be it layout designs, feasibility studies, systems design or custom design. ADSYS offers foundation drawings, mechanical design drawings, manufacturing drawings and more.</p>
          <h2>Manufacturing (All Mechanical Parts and Spare Parts)</h2>
          <p>ADSYS also manufactures machinery or equipment which are based on the client’s requirements. Our versatile manufacturing operations range from designing special-purpose machines and material handling systems for large volumes of goods. In addition, we offer the manufacturing of special spare parts on request.</p>
          <h2>Electrical Control</h2>
          <p>ADSYS offers automation via electrical control, an innovative technology highly desirable in today's rapidly advancing world. Existing manual operation functions are converted into automatic control functions, allowing greater efficiency while reducing error and cost. This involves the design and installation of control systems (both hardware and software), customised PLC software, intuitive human interaction interfaces, and machine interfacing between different models.</p>
          <h2>Site Installation and Commissioning</h2>
          <p>Used and new equipment can be installed according to client specifications, in addition to commissioning and interfacing such equipment.</p>
          <h2>General Maintenance</h2>
          <p>In addition to managing, designing, manufacturing, and installing equipment or systems, ADSYS also offers general maintenance, such as servicing material handling equipment or special purpose-built machines.</p>

        </div>
      </div>
    </div>
  )
}

export default Services