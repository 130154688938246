import React from 'react';
import './App.css'

import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";

import Header from './layouts/Header';
import Footer from './layouts/Footer';

import Home from './pages/home/Home';
import Systems from './pages/systems/Systems';
import Control from './pages/control/Control';
import Products from './pages/products/Products';
import Services from './pages/services/Services';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Gallery from './pages/gallery/Gallery';

const App = () => (
    <>
    <Router>
    <Header/>
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/systems' element={<Systems/>}/>
        <Route path='/control' element={<Control/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/contact' element={<Contact/>}/>
    </Routes>
    <Footer/>
    </Router>
    </>
)

export default App;

