import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div>
      <div className="footer-container">
        <div className="footer-upper">
          <div className="footer-upper-content">
            <div className="footer-contact">
              <h2>Contact Us</h2>
              <p>0423 809 809</p>
              <p>107 Old Progress Road</p>
              <p>QLD Richlands 4077</p>
              <p>sales@adsys.com.au</p>
            </div>
            <div className="footer-links">
              {/* <p>Home</p>
              <p>Services</p>
              <p>Products</p>
              <p>Contact</p> */}
            </div>
          </div>
        </div>
        <div className="footer-lower">
          <div className="footer-lower-content">
            <div className="footer-copyright">Copyright 2022 © ADSYS PTY LTD. All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer