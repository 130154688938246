import React from 'react'
import './Home.css'
import testImg from '../../assets/testimg.jpg'

// Global Component Imports
import SectionBreak from '../../components/SectionBreak'

// Hero Carousel Imports
import HeroSlider from './components/HeroSlider'
import hero1 from '../../assets/hero/20161125_100033.jpg'
import hero2 from '../../assets/hero/20190129_122145.jpg'
// import hero3 from '../../assets/hero/surf.jpeg'

// Hero Card Imports
import OutfeedHero from '../../assets/productCards/outfeed.jpg'
import BundleConv from '../../assets/productCards/bundleconv.jpg'
import LoadFormer from '../../assets/productCards/loadformer.jpg'
import PalletInserter from '../../assets/productCards/palletinserter.jpg'
import HMI from '../../assets/products/HMI.png'

// Creative Engineering Solutions Imports
import AdsysAboutImg from '../../assets/home/robotarm.png'

// Conveyer Systems Import
import CardSlider from './components/CardSlider'
import Belt from '../../assets/photos/other/Belt.jpg'
import Chain from '../../assets/photos/other/Chain.jpg'
import Infeed from '../../assets/photos/other/Infeed.jpg'
import Outfeed from '../../assets/photos/other/Outfeed.jpg'
import Roller from '../../assets/photos/other/Roller.jpg'
import Turning from '../../assets/photos/other/Turning.jpg'
import Turntable from '../../assets/photos/other/Turntable.jpg'
import XY from '../../assets/photos/other/XY.jpg'

// Control Systems Import
import PlcProgram from '../../assets/home/plcprogram.png'


const Home = () => (
  <>
  <div className='home-page'>
    <HeroCarousel/>
      <SectionBreak/>
    <ProductCards/>
      <SectionBreak/>
    <AboutAdsys/>
      <SectionBreak/>
    <ConveyerSystems/>
    <ConveyerSystemsSlider/>
      <SectionBreak/>
    {/* <HeroCards/>
    <About/>
    <ConveyerSystems/>
    <ControlSystems/> */}
    <ControlServices/>
    <SectionBreak/>
    {/* <GantrySection/> */}
    {/* <SectionBreak/> */}
  </div>
  </>
)

const HeroCarousel = () => {
  const slides = [
    {image: hero1, title: 'Corrugated Conveyers', subtitle: 'Find the Solution Now.'},
    {image: hero2, title: 'Bundle Systems', subtitle: 'Various Systems for Material Handling.'},
    // {image: hero3, title: 'Gantry Crane', subtitle: 'Designed to your Specifications'},
  ]
  return (
    <>
    <div className='hero-slider-container'>
      <HeroSlider slides={slides}/> 
    </div>
    </>
  )
}

const ProductCards = () => {
  const cards = [
    {
      image: OutfeedHero, 
      topheading: 'Conveyers', 
      title: 'Corrugated Systems', 
      subheading: 'See More'
    },
    {
      image: BundleConv, 
      topheading: 'Conveyers', 
      title: 'Bundle Transfers', 
      subheading: 'See More'
    },
    {
      image: LoadFormer, 
      topheading: 'Bundle Handling', 
      title: 'Load Formers', 
      subheading: 'See More'
    },
    {
      image: PalletInserter, 
      topheading: 'Finished Goods', 
      title: 'Pallet Inserters ', 
      subheading: 'See More'
    },
    {
      image: HMI, 
      topheading: 'Intuitive', 
      title: 'HMI & Interfacing', 
      subheading: 'See More'
    },
    {
      image: hero1, 
      topheading: 'High Quality', 
      title: 'Custom Systems', 
      subheading: 'Contact Us'
    },
  ]
  return (
    <>
    <div className="product-cards-title-container">
      <div className="product-cards-title-content">
        <h1>Custom Built Packages</h1>
      </div>
    </div>
      <div className="product-cards-container">
        <div className="product-cards-content">
          {cards.map((card, index) => (
            <div key={index}>
              <div className="product-card-map"
              style={{backgroundImage: `url(${card.image})`}}>
                <h4 className="pcm-topheading">
                  {card.topheading}
                </h4>
                <h2 className="pcm-title">
                  {card.title}
                </h2>
                <h4 className="pcm-subheading">
                  {card.subheading}
                </h4>                
              </div>
            </div>
          ))}
        </div> 
      </div>
    </>
  )
}


const AboutAdsys = () => {
  return (
      <>
      <div className="summary-container">
        <div className="summary-content">
          <div className="summary-image"
            style={{backgroundImage: `url(${AdsysAboutImg})`}}>
          </div>
          <div className="summary-text">
            <h1>Creative Engineering Solutions</h1>
            <br />
            <p>
              ADSYS Pty Ltd are an Australian based engineering company established in 2005, specialising in design, manufacture, installation and project management. We deliver innovative, efficient and customised material handling solutions for a variety of industrial sectors using specialised equipment and precision control systems.
            </p>
            <br />
            <p>
              ADSYS focuses on providing fully automated systems complying with all current health and safety standards to our clients and their employees, in addition to reducing operational costs. We offer a professional, reliable and customised solution to meet your needs.
            </p>
            <br />
            <p>
              For more information, please review our services, products or contact us
            </p>      
          </div>
        </div>
      </div>
      </>
  )
}

const ConveyerSystems = () => {
  return (
    <>
      <div className="conveyersystems-container">
        <div className="conveyersystems-content">
          <div className="conveyersystems-text">
            <h1>Conveyer Systems</h1>
            <br />
            <p>
              As an innovative, flexible and competitive company, we at ADSYS locally design, manufacture and program all our products specific to the customer's needs, whether it be a turnkey system or a conveyor system.  We completely manage from design through to installation and maintenance, or simply supplying a supplementary system to an existing machine. 
            </p>
            <br />
            <p>
              Regardless, we offer efficient, high quality, cost effective material handling products complying to all Australian health and safety standards customised to your requirements.
            </p>
            <br />
            <p>
            For more information, please review our services, products or contact us
            </p>      
          </div>
          <div className="conveyersystems-image"
            style={{backgroundImage: `url(${testImg})`}}>
          </div>
        </div>
      </div>
      </>
  )
}

const ConveyerSystemsSlider = () => {
  const conveyerTypeCards = [
    {title: 'Belt', image: Belt},
    {title: 'Roller', image: Roller},
    {title: 'Chain', image: Chain},
    {title: 'X-Y', image: XY},
    {title: 'Turntable', image: Turntable},
    {title: 'Turning', image: Turning},
    {title: 'Outfeed', image: Outfeed},
    {title: 'Infeed', image: Infeed},
  ]
  return (
    <div className='convsystemsslider-container'>
      <CardSlider cards={conveyerTypeCards}/>
    </div>
  )
}

const ControlServices = () => {
  return (
    <>
    <div className="controlservices-container">
      <div className="controlservices-content">
        <div className="controlservices-image"
          style={{backgroundImage: `url(${PlcProgram})`}}>
        </div>
        <div className="controlservices-text">
          <h1>Control Systems</h1>
          <br />
          <p>
            ADSYS offers automation via electrical control, an innovative technology that is highly desirable in today's rapidly advancing technological world. Existing manual operation functions are converted into automatic control functions, allowing greater efficiency while reducing errors and costs. This involves the design and installation of control systems (both hardware and software).
          </p>
          <br />
          <p>
            ADSYS uses the highest quality PLC software and components to create efficient conveyer transfer logic, intuitive human interaction interfaces and machine interfacing between different models.
          </p>        
        </div>
      </div>
      <div className="controlservices-logos">
          <ul>
            <li>Omron</li>
            <li>Schnieder</li>
            <li>Siemens</li>
            <li>Rockwell</li>
          </ul>
          <ul>
            <li>Delta</li>
            <li>ABB</li>
            <li>SICK</li>
            <li>Allen Bradley</li>
          </ul>
        </div>
        <sub className="controlservices-logos-disclaimer">
          DISCLAIMER:
          <br/>
          All trademarks, logos and brand names are the property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names, trademarks and brands does not imply endorsement.
        </sub>
    </div>
    </>
  )
}

// const GantrySection = () => {
//   return (
//     <>
//     <div className="gantrysection-container">
//       <div className="gantrysection-content">
//         <div className="gantrysection-text">
//           <h1>Gantry Crane</h1>
//           <br />
//           <p>
//             ADSYS is now pleased to offer a new system to the market, the gantry crane. Ideal for smaller scale businesses and warehouses, we offer robust overhead cranes with the same high level and intuitive electrical control we use accross all of our systems. 
//           </p>
//           <br />
//           <p>
//             We offer up to 3 tonne rated lifting and the product can be customised to your specific needs. We can handle pre-site inspections and will fully design to optiminally fit any setting. Site installation and commissioning come as standard with our packages.
//           </p>
//           <br />
//           <p>
//             To see more, click the link below.
//           </p>
//         </div>
//         <div className="gantrysection-image"
//           style={{backgroundImage: `url(${testImg})`}}>
//         </div>
//       </div>
//     </div>
//     </>
//   )
// }

export default Home